import React from 'react'

import { getDirection, formatDistance } from './helpers'
import { useIntl } from 'gatsby-plugin-react-intl'

import {
    Spacing,
    SharedStyles,
    Container,
    ListInfo,
} from '../../kenra-storybook/index'

const { StSectionInfo, StTitle } = SharedStyles

export default function SalonLocatorList({ salons, search }) {
    let intl = useIntl()
    let foundTitle = `${intl.formatMessage({
        id: 'salon-locator-found-salons-1',
    })} ${salons.length} ${intl.formatMessage({
        id: 'salon-locator-found-salons-2',
    })}`
    salons.sort((a, b) => (a.distance > b.distance ? 1 : -1))
    return (
        <>
            <Spacing removeSpaceBottom className="only-desktop">
                <StSectionInfo>
                    <Container>
                        <StTitle colorBlack styleMedium>
                            <h2>{foundTitle}</h2>
                        </StTitle>
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <Container useLargeWidth>
                    <ListInfo
                        showMobileTitleResults
                        showMobilePin
                        items={salons.map(salon => ({
                            subtitle: formatDistance(salon.distance, intl),
                            title: salon.salonName,
                            text: `${salon.street} ${salon.city}, ${salon.state}, ${salon.zip}`,
                            textAction: intl.formatMessage({
                                id: 'salon-locator-get-directions',
                            }),
                            onClickTextAction: () => getDirection(salon),
                            phone: salon.phone,
                            website: salon.website,
                            color: salon.kenraColorSalon?.toUpperCase() === 'YES'
                        }))}
                    />
                </Container>
            </Spacing>
        </>
    )
}
