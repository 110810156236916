export const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY;

export function getDirection(salon) {
    window.open(
        `https://maps.google.com/?q=${salon.salonName} ${salon.street} ${salon.city}, ${salon.state}, ${salon.zip}`,
        '_blank'
    );
}

export async function getDistance(salon, search) {
    const salonLocation = {
        position: {
            lat: parseFloat(salon.lat),
            lng: parseFloat(salon.lng),
        },
    };
    const searchLocation = await getSearchLocation(search);
    const distance = getDistanceBetweenPoints(salonLocation, searchLocation);
    return `${distance} miles`;
}

export function getDistanceBetweenPoints(location1, location2) {
    if (
        !location2 ||
        !location2.position ||
        !location2.position.lat ||
        !location2.position.lng
    ) {
        return null;
    }
    if (
        !location1 ||
        !location1.position ||
        !location1.position.lat ||
        !location1.position.lng
    ) {
        return null;
    }
    return distance(
        location1.position.lat,
        location1.position.lng,
        location2.position.lat,
        location2.position.lng,
        'M'
    );
}

export async function getFilteredSalons(salons, searchString, intl) {
    try {
        if (searchString === '') {
            return [];
        }
        const threshold = process.env.GATSBY_SALON_THRESHOLD_DISTANCE || 200;
        const searchLocation = await getSearchLocation(searchString, intl);
        if (
            !searchLocation ||
            !searchLocation.position ||
            !searchLocation.position.lat ||
            !searchLocation.position.lng
        ) {
            return [];
        }
        const filteredSalons = [];
        salons.forEach(async salon => {
            const salonLocation = {
                position: {
                    lat: parseFloat(salon.lat),
                    lng: parseFloat(salon.lng),
                },
            };
            salon.distance = getDistanceBetweenPoints(
                salonLocation,
                searchLocation
            );
            if (
                salon.distance !== null &&
                parseFloat(salon.distance) < threshold
            ) {
                filteredSalons.push(salon);
            }
        });
        return filteredSalons;
    } catch (err) {
        console.log(err);
        return [];
    }
}

export function formatDistance(distanceInMiles, intl) {
    return `${parseFloat(distanceInMiles).toFixed(2)} ${intl.formatMessage({
        id: 'salon-locator-distance-miles',
    })}`;
}

// returns distance between two points in miles
function distance(lat1, lon1, lat2, lon2, unit) {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === 'Km') {
            dist = dist * 1.609344;
        }
        return dist;
    }
}

let locationCache = {};

export function getSalonString(salon) {
    return `${salon.salonName} ${salon.street} ${salon.city} ${salon.state} ${salon.zip}`;
}

function encode(data) {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

export async function getSearchLocation(searchString, intl) {
    let string = searchString;
    if (string in locationCache) {
        return locationCache[string];
    }
    let result = await fetch('/.netlify/functions/geocodingBroker', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
            searchTerm: string,
        }),
    })
        .then(async response => {
            if (!response || !response.ok) {
                throw new Error(intl.formatMessage({ id: 'search-error' }));
            } else if (response.status === 204) {
                //handle zero results
                throw new Error(
                    intl.formatMessage({ id: 'search-error-locating' })
                );
            } else {
                const responseJSON = await response.json();
                if (responseJSON && responseJSON.lat && responseJSON.lng) {
                    let location = {
                        position: {
                            lat: responseJSON.lat,
                            lng: responseJSON.lng,
                        },
                    };
                    locationCache[string] = location;
                    return location;
                } else {
                    throw new Error(intl.formatMessage({ id: 'search-error' }));
                }
            }
        })
        .catch(error => {
            alert(error.message);
        });
    return result;
}

export async function getUserLocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            });
        } else {
            console.error('No location available');
        }
    });
}
